import { createPinia } from 'pinia';
import { createApp } from 'vue'
import './style.css'
import App from './index.vue'
import { setupShoelaceDarkMode } from './lib/darkMode';
import { setupSentry } from './lib/sentry';

setupShoelaceDarkMode();

const pinia = createPinia();
const app = createApp(App);

setupSentry({app});

app.use(pinia);
app.mount('#app');
